import { useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AOS from "aos";
import NavScrollTop from './components/NavScrollTop';

const HomeTwo = lazy(() => import("./pages/HomeTwo"));
const About = lazy(() => import("./pages/About"));
const Service = lazy(() => import("./pages/Service"));
const Contact = lazy(() => import("./pages/Contact"));

function App() {
  useEffect(() => {
    AOS.init({
      offset: 80,
      duration: 1000,
      once: true,
      easing: 'ease',
    });
    AOS.refresh();
  }, []);

  return (
    <Router>
      <NavScrollTop>
        <Suspense fallback={<div />}>
          <Routes>
            <Route path={`${process.env.PUBLIC_URL + "/"}`} element={<HomeTwo />} />
            <Route path={`${process.env.PUBLIC_URL + "/home"}`} element={<HomeTwo />} />
            {/*
            <Route path={`${process.env.PUBLIC_URL + "/about"}`} element={<About />} />
            <Route path={`${process.env.PUBLIC_URL + "/service"}`} element={<Service />} />
                        */}
            <Route path={`${process.env.PUBLIC_URL + "/contact"}`} element={<Contact />} />

          </Routes>
        </Suspense>
      </NavScrollTop>
    </Router>
  );
}

export default App;
